import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const BarChart = ({ selectedRune, selectedTimeframe }) => {
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState({
    scales: {
      x: {
        display: false,
      },
      y: {
        beginAtZero: false,
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  });
  const [rune, setRune] = useState('');

  useEffect(() => {
    if (selectedRune) {
      setChartData(null); // Set chartData to null to show the loading message

      const fileName = selectedRune.replace(/•/g, '').toUpperCase() + '.json';
      fetch(`/${fileName}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log('Imported JSON data:', data);

          if (data && data.entries) {
            setRune(data.rune);

            // Calculate the step based on the selected timeframe
            const step = Math.max(1, Math.floor((selectedTimeframe / 10)));

            // Select the last 24 entries based on the step
            const holderCounts = [];
            for (let i = data.entries.length - 1; i >= 0 && holderCounts.length < 24; i -= step) {
              holderCounts.unshift(data.entries[i].holderCount);
            }

            // Ensure the last entry is always included if not already added
            if (holderCounts.length < 24 && holderCounts[holderCounts.length - 1] !== data.entries[data.entries.length - 1].holderCount) {
              holderCounts.push(data.entries[data.entries.length - 1].holderCount);
            }

            // Fill in the remaining slots with null values at the front
            while (holderCounts.length < 24) {
              holderCounts.unshift(null);
            }

            // Generate timestamps in the correct order
            const timestamps = Array.from({ length: 24 }, (_, index) => `${(23 - index) * selectedTimeframe} mins ago`);

            console.log('Holder Counts:', holderCounts);
            console.log('Timestamps:', timestamps);

            const minHolderCount = Math.min(...holderCounts.filter(count => count !== null));
            const maxHolderCount = Math.max(...holderCounts.filter(count => count !== null));
            const yAxisMin = minHolderCount === maxHolderCount ? minHolderCount - 1 : minHolderCount - (maxHolderCount - minHolderCount) * 0.1;

            const newChartData = {
              labels: timestamps,
              datasets: [
                {
                  label: 'Holder Count',
                  data: holderCounts,
                  backgroundColor: 'rgba(75, 192, 192, 0.6)',
                  borderColor: 'rgba(75, 192, 192, 1)',
                  borderWidth: 1,
                },
              ],
            };

            const newChartOptions = {
              scales: {
                x: {
                  display: false,
                },
                y: {
                  min: yAxisMin,
                  max: maxHolderCount,
                  display: false,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            };

            console.log('Chart Data:', newChartData);
            console.log('Chart Options:', newChartOptions);

            setChartData(newChartData);
            setChartOptions(newChartOptions);
          }
        })
        .catch(error => console.error('Error fetching JSON data:', error));
    }
  }, [selectedRune, selectedTimeframe]);

  if (!chartData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default BarChart;