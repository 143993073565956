import React, { useEffect, useState } from 'react';
import './App.css';
import BarChart from './BarChart';
import CustomDropdown from './CustomDropdown';
import CustomDropdownRune from './CustomDropdownRune';
import Select from 'react-select';

function App() {
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [dataAsc, setDataAsc] = useState([]); // New state for ascending sorted data
  const [charts, setCharts] = useState([{ selectedRune: 'PUPS•WORLD•PEACE', selectedTimeframe: 60 }]);
  const [selectedTableTimeframe, setSelectedTableTimeframe] = useState(60); // Default to 60 minutes for table

  useEffect(() => {
    fetch('./overview.json')
      .then(response => response.json())
      .then(data => {
        setAllData(data);
        const sortedData = sortData(data, 10);
        setData(sortedData.slice(0, 20));
        setDataAsc(sortedData.slice().reverse().slice(0, 20)); // Set ascending sorted data
        if (sortedData.length > 0) {
          setCharts([{ selectedRune: 'PUPS•WORLD•PEACE', selectedTimeframe: 60 }]);
        }
      })
      .catch(error => console.error('Error fetching the overview data:', error));
  }, []);

  useEffect(() => {
    const sortedData = sortData(allData, selectedTableTimeframe);
    setData(sortedData.slice(0, 20));
    setDataAsc(sortedData.slice().reverse().slice(0, 20)); // Update ascending sorted data
  }, [selectedTableTimeframe, allData]);

  const sortData = (data, timeframe) => {
    return data.sort((a, b) => {
      switch (timeframe) {
        case 30:
          return b.percentage_change_last_30_mins - a.percentage_change_last_30_mins;
        case 60:
          return b.percentage_change_last_1_hr - a.percentage_change_last_1_hr;
        case 240:
          return b.percentage_change_last_4_hrs - a.percentage_change_last_4_hrs;
        case 720:
          return b.percentage_change_last_12_hrs - a.percentage_change_last_12_hrs;
        case 1440:
            return b.percentage_change_last_1_day - a.percentage_change_last_1_day;  
        case 10:
        default:
          return b.percentage_change_last_10_mins - a.percentage_change_last_10_mins;
      }
    });
  };

  const handleRuneChange = (index, value) => {
    const newCharts = [...charts];
    newCharts[index].selectedRune = value;
    setCharts(newCharts);
  };

  const handleChartTimeframeChange = (index, value) => {
    const newCharts = [...charts];
    newCharts[index].selectedTimeframe = value;
    setCharts(newCharts);
  };

  const handleTableTimeframeChange = (value) => {
    setSelectedTableTimeframe(value);
  };

  const addChart = () => {
    setCharts([...charts, { selectedRune: '', selectedTimeframe: 60 }]);
  };

  const removeChart = (index) => {
    const newCharts = charts.filter((_, i) => i !== index);
    if (newCharts.length === 0) {
      newCharts.push({ selectedRune: '', selectedTimeframe: 60 });
    }
    setCharts(newCharts);
  };

  const getTimeframeData = (item, timeframe) => {
    switch (timeframe) {
      case 30:
        return {
          countChange: item.count_change_last_30_mins ?? 0,
          percentageChange: item.percentage_change_last_30_mins ?? 0
        };
      case 60:
        return {
          countChange: item.count_change_last_1_hr ?? 0,
          percentageChange: item.percentage_change_last_1_hr ?? 0
        };
      case 240:
        return {
          countChange: item.count_change_last_4_hrs ?? 0,
          percentageChange: item.percentage_change_last_4_hrs ?? 0
        };
      case 720:
        return {
          countChange: item.count_change_last_12_hrs ?? 0,
          percentageChange: item.percentage_change_last_12_hrs ?? 0
        };
      case 1440:
        return {
          countChange: item.count_change_last_1_day ?? 0,
          percentageChange: item.percentage_change_last_1_day ?? 0
        };     
      case 10:
      default:
        return {
          countChange: item.count_change_last_10_mins ?? 0,
          percentageChange: item.percentage_change_last_10_mins ?? 0
        };
    }
  };

  const safeRenderChange = (label, countChange, percentageChange) => {
    const safeCountChange = (countChange !== null && countChange !== undefined && !isNaN(countChange)) ? countChange : 'n/a';
    const safePercentageChange = (percentageChange !== null && percentageChange !== undefined && !isNaN(percentageChange)) ? percentageChange : 0;
    return renderChange(label, safeCountChange, safePercentageChange);
};

const renderChange = (label, countChange, percentageChange) => {
    const countColor = countChange > 0 ? '#6eff6e' : countChange < 0 ? '#ff8888' : 'white';
    const percentageColor = percentageChange > 0 ? '#6eff6e' : percentageChange < 0 ? '#ff8888' : 'white';
    const formattedPercentageChange = (percentageChange !== null && percentageChange !== undefined && !isNaN(percentageChange)) ? percentageChange.toFixed(2) : '0.00';
    
    return (
      <div>
        <p className="percent-text">
          {label}: <span style={{ color: countColor }}>{countChange}</span> / <span style={{ color: percentageColor }}>{formattedPercentageChange}%</span>
        </p>
      </div>
    );
};

  const getTimeframeLabel = (timeframe) => {
    switch (timeframe) {
      case 60:
        return '1hr';
      case 240:
        return '4hr';
      case 720:
        return '12hr';
      case 1440:
        return '1d';  
      default:
        return `${timeframe}m`;
    }
  };

  const getRuneLogoUrl = (rune) => {
    if (rune === "PUPS•WORLD•PEACE") {
      return "https://pbs.twimg.com/profile_images/1859004497596416000/mvagabHM_400x400.jpg";
    }
    return `https://static.unisat.io/icon/runes/${rune}`;
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <div className="row">
          {charts.map((chart, index) => {
            const selectedRuneData = allData.find(item => item.rune === chart.selectedRune);
            const holderCount = selectedRuneData?.most_recent_holder_count ?? 0;
  
            return (
              <div className="column" key={index}>
                <div className="chart-card">
                  <div className="chart-header">
                    {chart.selectedRune && (
                      <h3 className="rune-header">
                        <img src={getRuneLogoUrl(chart.selectedRune)} alt={chart.selectedRune} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                        {chart.selectedRune}
                      </h3>
                    )}
                    {chart.selectedRune && <p className="holder-text">{holderCount.toLocaleString()} Holders</p>}
                    {chart.selectedRune && selectedRuneData && (
                      <>
                        {safeRenderChange('1hr', selectedRuneData.count_change_last_1_hr ?? 'n/a', selectedRuneData.percentage_change_last_1_hr ?? 'n/a')}
                        {safeRenderChange('4hr', selectedRuneData.count_change_last_4_hrs ?? 'n/a', selectedRuneData.percentage_change_last_4_hrs ?? 'n/a')}
                        {safeRenderChange('12hr', selectedRuneData.count_change_last_12_hrs ?? 'n/a', selectedRuneData.percentage_change_last_12_hrs ?? 'n/a')}
                        {safeRenderChange('1d', selectedRuneData.count_change_last_1_day ?? 'n/a', selectedRuneData.percentage_change_last_1_day ?? 'n/a')}
                      </>
                    )}
                    <button className="remove-chart" onClick={() => removeChart(index)}>x</button>
                  </div>
                  {!chart.selectedRune && (
                    <CustomDropdownRune
                      options={allData.sort((a, b) => a.rune.localeCompare(b.rune)).map(item => ({ value: item.rune, label: item.rune }))}
                      value={chart.selectedRune}
                      onChange={(value) => handleRuneChange(index, value)}
                      placeholder="Select Rune ▾"
                      isSearchable={true}
                    />
                  )}
                  {chart.selectedRune && (
                    <>
                      <CustomDropdown
                        options={[
                          { value: 10, label: '10min' },
                          { value: 30, label: '30min' },
                          { value: 60, label: '1hr' },
                          { value: 240, label: '4hr' },
                          { value: 720, label: '12hr' },
                          { value: 1440, label: '1d' }
                        ]}
                        value={chart.selectedTimeframe}
                        onChange={(value) => handleChartTimeframeChange(index, value)}
                      />
                      <BarChart selectedRune={chart.selectedRune} selectedTimeframe={chart.selectedTimeframe} />
                    </>
                  )}
                </div>
              </div>
            );
          })}
          {charts.length < 5 && (
            <div className="column add-chart" onClick={addChart}>
              <div className="add-icon">+</div>
            </div>
          )}
          {Array.from({ length: Math.max(0, 4 - charts.length) }).map((_, index) => (
            <div className="column empty-column" key={`empty-${index}`}></div>
          ))}
        </div>
        <div className="row2">
          <div className="column1">
            <div className="extra">
              <div className="extra2">
                <div className="holder-trends-header">
                  <h2 className="table-title">Holder Trends</h2>
                  <CustomDropdown
                    options={[
                      { value: 10, label: '10min' },
                      { value: 30, label: '30min' },
                      { value: 60, label: '1hr' },
                      { value: 240, label: '4hr' },
                      { value: 720, label: '12hr' },
                      { value: 1440, label: '1d' },
                    ]}
                    value={selectedTableTimeframe}
                    onChange={handleTableTimeframeChange}
                  />
                </div>
              </div>
              <div className="column2-container">
                <div className="column2">
                  <div className="table-container table-wrapper">
                    <div className="table-header">
                      <h3 className="table-title">Biggest Gainers</h3>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Rune</th>
                          <th>Holders</th>
                          <th>{getTimeframeLabel(selectedTableTimeframe)}</th>
                          <th>{getTimeframeLabel(selectedTableTimeframe)} %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => {
                          const { countChange, percentageChange } = getTimeframeData(item, selectedTableTimeframe);
                          return (
<tr key={index}>
  <td className="rune-header">
    <img src={getRuneLogoUrl(item.rune)} alt={item.rune} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
    {item.rune}
  </td>
  <td>{item.most_recent_holder_count}</td>
  <td style={{ color: countChange > 0 ? '#6eff6e' : countChange < 0 ? '#ff8888' : 'white' }}>
    {countChange}
  </td>
  <td style={{ color: percentageChange > 0 ? '#6eff6e' : percentageChange < 0 ? '#ff8888' : 'white' }}>
    {percentageChange !== null && percentageChange !== undefined ? `${percentageChange.toFixed(2)}%` : '0%'}
  </td>
</tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="column2">
                  <div className="table-container table-wrapper">
                    <div className="table-header">
                      <h3 className="table-title">Biggest Losers</h3>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Rune</th>
                          <th>Holders</th>
                          <th>{getTimeframeLabel(selectedTableTimeframe)}</th>
                          <th>{getTimeframeLabel(selectedTableTimeframe)} %</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataAsc.map((item, index) => {
                          const { countChange, percentageChange } = getTimeframeData(item, selectedTableTimeframe);
                          return (
<tr key={index}>
  <td className="rune-header">
    <img src={getRuneLogoUrl(item.rune)} alt={item.rune} style={{ width: '20px', height: '20px', marginRight: '8px' }} />
    {item.rune}
  </td>
  <td>{item.most_recent_holder_count}</td>
  <td style={{ color: countChange > 0 ? '#6eff6e' : countChange < 0 ? '#ff8888' : 'white' }}>
    {countChange}
  </td>
  <td style={{ color: percentageChange > 0 ? '#6eff6e' : percentageChange < 0 ? '#ff8888' : 'white' }}>
    {percentageChange !== null && percentageChange !== undefined ? `${percentageChange.toFixed(2)}%` : '0%'}
  </td>
</tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;