import React, { useState, useRef, useEffect } from 'react';
import './App.css';

const CustomDropdown = ({ options, value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [inputPlaceholder, setInputPlaceholder] = useState(`${placeholder}`);
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
    setSearchTerm('');
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFocus = () => {
    setInputPlaceholder('');
    setIsOpen(true);
  };

  const handleBlur = () => {
    if (searchTerm === '') {
      setInputPlaceholder(`${placeholder}`);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const normalizeString = (str) => {
    return str.toLowerCase().replace(/•/g, '').replace(/\s+/g, '');
  };

  const filteredOptions = options.filter(option => {
    const normalizedLabel = normalizeString(option.label);
    const normalizedSearchTerm = normalizeString(searchTerm);
    return normalizedLabel.includes(normalizedSearchTerm);
  });

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="custom-dropdown-selected">
        <input
          type="text"
          className="custom-dropdown-search"
          placeholder={inputPlaceholder}
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
      {isOpen && (
        <div className="custom-dropdown-options">
          {filteredOptions.map((option, index) => (
            <div
              key={index}
              className="custom-dropdown-option"
              onClick={() => handleOptionClick(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;